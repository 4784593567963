@import '@marriott/global-styles/dist/scss/base/variables/colors';

.m-header__nav {
  .m-header__sub-nav {
    padding: 0;
    .cl__list {
      padding: 0;
    }
    @media screen and (min-width: 992px) {
      padding-bottom: 3.25rem;
      padding-top: 2.875rem;

      .cardlinks.aem-GridColumn.aem-GridColumn--default--3 {
        padding: 0;
      }
    }

    @media screen and (max-width: 992px) {
      .aem-Grid.aem-Grid--default--12 > .aem-GridColumn {
        &.cardlinks,
        &.cardarticle {
          float: left;
        }
      }
    }
  }
}
.comparison-page .cmp-container .m-header {
  position: unset;
}
#terms_conditions {
  scroll-margin-top: 48px;
  @media screen and (min-width: 992px) {
    scroll-margin-top: 106px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1024px) {
  .m-header__nav-items {
    margin-top: 0;
  }
}

@media screen and (min-width: 1024px) {
  .m-header {
    right: 0;
    left: 0;
  }

  .m-header__container .m-header__content {
    padding: 0;
  }
}

@media screen and (min-width: 1025px) {
  .m-header__container .m-header__content {
    .m-header__logo-nav-items {
      margin-left: -0.5rem;
    }
  }
}

// COLORS

$icon-colors: (
  member: $member-solid,
  member-alt: $member-solidAlt,
  silver: $silver-solid,
  gold: $gold-solid,
  platinum: $platinum-solid,
  titanium: $titanium-solid,
  ambassador: $ambassador-solid,
);

@each $name, $color in $icon-colors {
  .icon-color-#{$name} {
    color: $color;
  }
  .icon-check-#{$name} {
    background: $color;
  }
}
